<template>
  <div class="about">
    <div class="hero is-info">
      <div class="container">
        <h1 class="is-size-3">{{ $t("profile.title") }}</h1>
      </div>
    </div>
    <hr>
    <div class="columns is-centered">
      <div class="column is-5-tablet is-4-desktop is-3-widescreen">
        <div class="box">
          <div class="field">
            <label for="" class="label">{{ $t("profile.email") }}</label>
            <div class="control has-icons-left">
              <input type="email" v-bind:placeholder="$t('profile.ph-email')" class="input" v-model="user.email" required>
              <span class="icon is-small is-left">
                <i class="fa fa-envelope"></i>
              </span>
            </div>
          </div>
          <div class="field">
            <label for="" class="label">{{ $t("profile.display") }}</label>
            <div class="control has-icons-left">
              <input type="text" v-bind:placeholder="$t('profile.ph-display')" class="input" v-model="user.displayName">
              <span class="icon is-small is-left">
                <i class="fa fa-envelope"></i>
              </span>
            </div>
          </div>

          <div class="field">
            <label for="" class="label">{{ $t("profile.name") }}</label>
            <div class="control has-icons-left">
              <input type="text" v-bind:placeholder="$t('profile.ph-name')" class="input" v-model="user.name">
              <span class="icon is-small is-left">
                <i class="fa fa-envelope"></i>
              </span>
            </div>
          </div>

          <div class="field">
            <button class="button is-info" @click="save" v-tooltip="$t('profile.save')">
              <font-awesome-icon icon="save" />
            </button>
          </div>
          <!--div class="field">
            <button class="button is-success is-right" type="reset" >Reset</button>
          </div-->
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import {UserClient} from "../pb/user_service_grpc_web_pb";
import {GetUserRequest, UpdateUserRequest} from "../pb/user_service_pb";
import {UserProfile, Origin, DetailAddress} from "../pb/model_pb"
import environment from "@/utility/environment"
import common from "@/utility/common"

export default {
  name: 'Profile',
  data() {
    return {
      user: {}
    }
  },
  methods: {
    save: function() {
      let today = new Date()
      let ts = new proto.google.protobuf.Timestamp()
      ts.setSeconds(Math.floor(today.getTime() / 1000))
      ts.setNanos((today.getTime() % 1000) * 1e6)
      let token = this.$cookies.get("$t")
      let metadata = {'authorization': token}
      this.client = new UserClient(common.SECURE_HOST, null, {
        "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
        "grpc.max_send_message_length": common.MESSAGE_LENGTH
      })
      let updateUserRequest = new UpdateUserRequest()
      let userToUpdate = new UserProfile()
      userToUpdate.setEmail(this.user.email)
      userToUpdate.setPassword(this.user.password)
      userToUpdate.setName(this.user.name)
      userToUpdate.setOrganization(this.user.organization)
      userToUpdate.setRole(this.user.role)
      userToUpdate.setCreatedAt(ts)
      userToUpdate.setStatus(this.user.status)
      userToUpdate.setDisplayName(this.user.displayName)
      userToUpdate.setCurrentToken(this.user.currentToken)
      userToUpdate.setPhoneNumber(this.user.phoneNumber)
      let address = this.user.address
      if (address == null || address == undefined) {
        address = new DetailAddress()
        address.setStreetNumber("")
        address.setCity('')
        address.setProvince("")
        address.setCountry("USA")
        address.setZipCode("")
        address.setLatitude(0.0)
        address.setLongtitude(0.0)
        address.setTimezone("")
      }
      userToUpdate.setAddress(address)
      userToUpdate.setUpdatedAt(ts)
      let origin = new Origin()
      origin.setLoginAt(ts)
      origin.setBrowser(environment.getBrowser())
      origin.setOs(environment.getOS())
      let origins = []
      origins = this.user.origins
      if (origins == [] || origins == undefined || origins == null) {
        origins = []
      }
      origins.push(origin)
      userToUpdate.setOriginsList(origins)
      updateUserRequest.setUserToUpdate(userToUpdate)
      this.client.updateUser(updateUserRequest, metadata, (err, response)=>{
        if (err != null) {
          alert("Failed to update user profile: " + err)
          return
        }
        if (response != null) {
          alert(response.getMessage())
        }
      })
    }
  },
  mounted() {
    let uid = this.$cookies.get("$uid")
    let token = this.$cookies.get("$t")

    if (this.$store.getters.isLogIn) {
      this.user = this.$store.getters.getUser
      if (this.user.email == "" || this.user.email == undefined || this.user.email == null) {
        let userEmail = this.CryptoJS.AES.decrypt(
            uid, "jumbosecret").toString(this.CryptoJS.enc.Utf8);

        let metadata = {'authorization': token}
        this.client = new UserClient(common.SECURE_HOST, null, {
          "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
          "grpc.max_send_message_length": common.MESSAGE_LENGTH
        })
        let getUserRequest = new GetUserRequest()
        getUserRequest.setEmail(userEmail)
        this.client.getUser(getUserRequest, metadata, (err, response)=>{
          if (err != null) {
            alert("Failed to get user: " + err)
            return
          }
          if (response != null) {
            this.user = response.toObject().usersList[0]
            this.$store.commit('setLoggedInUser', this.user)
            this.$root.$emit('updateAuth', true)
          }
        })
      }
    }
  }
}
</script>